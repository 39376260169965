import { useState, useCallback } from "react";
import { FullDataWithMeta } from "../../../util/data_actions";

interface UseHiddenRowsReturn {
  hiddenRows: Set<number>;
  hiddenRowsVersion: number;
  currentFilter: "rowErrors" | "singleColumnErrors" | "none";
  currentColumnIndexErrorFilter: number | null;

  showAllRows: () => void;
  showOnlyRowsWithErrors: (
    fullData: FullDataWithMeta,
    rowsWithErrors: Set<number>
  ) => void;
  showOnlySingleColumnErrors: (
    fullData: FullDataWithMeta,
    colErrors: Map<number, number[]>,
    colIndex: number
  ) => void;
}

export const useHiddenRows = (): UseHiddenRowsReturn => {
  // hiddenRows is a set of row indices that are hidden
  const [hiddenRows, setHiddenRows] = useState<Set<number>>(new Set());

  // hiddenRowsVersion is a version number that is incremented when the
  // hiddenRows set is updated. This is used to force a re-render of the
  // component when the hiddenRows set is updated without an expnsive
  // equalTo comparison.
  const [hiddenRowsVersion, setHiddenRowsVersion] = useState<number>(0);

  // currentFilter is the current error filter that is being used which can be
  // used to determine UI state in the DataReviewModal
  const [currentFilter, setCurrentFilter] = useState<
    "rowErrors" | "singleColumnErrors" | "none"
  >("none");

  // currentColumnIndexErrorFilter is the current column index that is being
  // used to filter errors which can be used to determine UI state in the
  // DataReviewModal
  const [currentColumnIndexErrorFilter, setCurrentColumnIndexErrorFilter] =
    useState<number | null>(null);

  const showAllRows = useCallback(() => {
    setHiddenRows(new Set());
    setCurrentFilter("none");
    setCurrentColumnIndexErrorFilter(null);
    setHiddenRowsVersion((v) => v + 1);
  }, []);

  const showOnlyRowsWithErrors = useCallback(
    (fullData: FullDataWithMeta, rowsWithErrors: Set<number>) => {
      const hiddenRowSet = new Set<number>(
        Array.from(Array(fullData.length).keys())
      );

      rowsWithErrors.forEach((rowIndex) => hiddenRowSet.delete(rowIndex));

      setHiddenRows(hiddenRowSet);
      setCurrentFilter("rowErrors");
      setCurrentColumnIndexErrorFilter(null);
      setHiddenRowsVersion((v) => v + 1);
    },
    []
  );

  const showOnlySingleColumnErrors = useCallback(
    (
      fullData: FullDataWithMeta,
      colErrors: Map<number, number[]>,
      colIndex: number
    ) => {
      const hiddenRowSet = new Set<number>(
        Array.from(Array(fullData.length).keys())
      );

      const errors = colErrors.get(colIndex);
      if (errors !== undefined) {
        errors.forEach((rowIndex) => hiddenRowSet.delete(rowIndex));
      }

      setHiddenRows(hiddenRowSet);
      setCurrentFilter("singleColumnErrors");
      setCurrentColumnIndexErrorFilter(colIndex);
      setHiddenRowsVersion((v) => v + 1);
    },
    []
  );

  return {
    hiddenRows,
    hiddenRowsVersion,
    currentFilter,
    currentColumnIndexErrorFilter,

    showAllRows,
    showOnlyRowsWithErrors,
    showOnlySingleColumnErrors,
  };
};
