import {
  IDeveloperField,
  ITableMessageInternal,
  IUniqueWithValidator,
  IValidatorField,
  INewTableMessages,
} from "../interfaces";

import { cellIsEmpty, transpose } from "./TableHelpers";
import MapWithDefault from "../util/MapWithDefault";
import { IField } from "../fields";
import { FullDataWithMeta } from "../util/data_actions";
import validateUnique from "./validators/validateUnique";
import validateRequireWith from "./validators/validateRequireWith";
import validateUniqueWith from "./validators/validateUniqueWith";
import validateRequired from "./validators/validateRequired";
import validateRegex from "./validators/validateRegex";
import validateAlphabetical from "./validators/validateAlphabetical";
import validateLength from "./validators/validateLength";

// Used for storing state for unique_with validations.
// A map of maps where the top level map has unique_with string keys
// Pointing to maps of strings of row values to row indexes that have that value
export type IUniqueWithDict = MapWithDefault<
  string,
  MapWithDefault<string, Set<number>>
>;

/**
 * Runs validators on the data.
 *
 * @param data - The full data to validate.
 * @param columnMapping - The column mapping.
 * @param fieldInstances - The field instances.
 * @param transformErrorCells - The set of cells that have transform errors.
 * @param rowsChanged - The rows that have changed. This will only run row
 * validations on these rows. If null, all rows will be validated.
 * @returns The new table messages.
 */
export function runValidators(
  data: FullDataWithMeta,
  columnMapping: Map<number, IDeveloperField>,
  fieldInstances: Map<number, IField>,
  transformErrorCells: Set<string>,
  rowsChanged: number[] | null
): INewTableMessages {
  const newTableMessages: INewTableMessages = [];
  const transposeData = transpose(data);

  // a map of field keys to validators on that field
  const columnToValidators = new Map<number, IValidatorField[]>();

  columnMapping.forEach((field: IDeveloperField, colIndex: number) => {
    if (field.validators) {
      columnToValidators.set(colIndex, field.validators);
    }
  });

  // We only want to validate rows that have data in at least one column
  let rowsToValidate: number[] = [];
  data.forEach((row, index) => {
    if (
      // Only consider data in columns we're actually mapping
      row.some(
        (cell, colIdx) => !cellIsEmpty(cell) && columnMapping.has(colIdx)
      )
    ) {
      rowsToValidate.push(index);
    }
  });

  const uniqueWithValidators = new MapWithDefault<
    string,
    Set<[number, IUniqueWithValidator]>
  >(() => new Set());

  // validators that operate on all data in a single column
  columnToValidators.forEach((validators, colIndex) => {
    const field = fieldInstances.get(colIndex)!;

    validators.forEach((validator) => {
      if (
        validator.validate === "unique" ||
        validator.validate === "unique_case_insensitive"
      ) {
        validateUnique(
          validator,
          field,
          colIndex,
          transposeData[colIndex],
          newTableMessages,
          transformErrorCells
        );
      }

      if (validator.validate === "unique_with") {
        uniqueWithValidators
          .get(validator.uniqueKey)
          .add([colIndex, validator]);
      }
    });
  });

  // Unique with is pretty expensive so only run if there are uniqueness validators
  if (uniqueWithValidators.size > 0) {
    // Map of unique keys to map of values and rows with that value
    const globalUniqueWithDict: IUniqueWithDict = new MapWithDefault(
      () => new MapWithDefault(() => new Set())
    );

    rowsToValidate.forEach((rowIndex: number) => {
      const rowUniqueWithDict = new MapWithDefault<string, string[]>(() => []);

      columnToValidators.forEach((validators, colIndex) => {
        validators.forEach((validator) => {
          if (validator.validate === "unique_with") {
            const cellValue = data[rowIndex][colIndex];
            processUniqueWithForCell(validator, cellValue, rowUniqueWithDict);
          }
        });
      });
      processUniqueWithForRow(
        globalUniqueWithDict,
        rowUniqueWithDict,
        rowIndex
      );
    });

    validateUniqueWith(
      uniqueWithValidators,
      globalUniqueWithDict,
      newTableMessages
    );
  }

  // Intersect rowsToValidate with rowsChanged to only validate rows that have
  // changed. We do this here because we need to run through all the rows for
  // the unique_with validators. This filter should always exist after the
  // column validations are run.
  if (rowsChanged) {
    rowsToValidate = rowsToValidate.filter((row) => rowsChanged.includes(row));
  }
  rowsToValidate.forEach((rowIndex: number) => {
    columnToValidators.forEach((validators, colIndex) => {
      validators.forEach((validator) => {
        let cellValue = data[rowIndex][colIndex];
        let newMessages: ITableMessageInternal[] = [];

        const cellRef = `${rowIndex},${colIndex}`;
        const field = fieldInstances.get(colIndex)!;

        if (!transformErrorCells.has(cellRef)) {
          cellValue = field.getDisplayValueChecked(cellValue as any, rowIndex);
        }

        switch (validator.validate) {
          case "required":
            newMessages = validateRequired(validator, cellValue);
            break;
          case "regex_match":
          case "regex_exclude":
            newMessages = validateRegex(validator, cellValue);
            break;
          case "length":
            newMessages = validateLength(validator, cellValue);
            break;
          case "alphabetical":
            newMessages = validateAlphabetical(validator, cellValue);
            break;
          case "require_with":
          case "require_without":
          case "require_with_all":
          case "require_without_all":
          case "require_with_values":
          case "require_without_values":
          case "require_with_all_values":
          case "require_without_all_values":
            newMessages = validateRequireWith(
              validator,
              cellValue,
              data[rowIndex],
              columnMapping
            );
            break;
        }

        if (newMessages.length > 0) {
          newTableMessages.push([rowIndex, colIndex, newMessages]);
        }
      });
    });
  });

  return newTableMessages;
}

function processUniqueWithForCell(
  validator: IUniqueWithValidator,
  cellValue: unknown,
  rowUniqueWithDict: MapWithDefault<string, unknown[]>
): void {
  rowUniqueWithDict.get(validator.uniqueKey).push(cellValue);
}

function processUniqueWithForRow(
  globalDict: IUniqueWithDict,
  rowDict: MapWithDefault<string, string[]>,
  rowIndex: number
): void {
  rowDict.forEach((valueArray, uniqueKey) => {
    const valueStr = valueArray.join(";;");
    globalDict.get(uniqueKey).get(valueStr).add(rowIndex);
  });
}

export function getValidatorMessage(
  validator: IValidatorField,
  defaultMessage: string
): ITableMessageInternal {
  const validatorType = [
    "unique_with",
    "unique",
    "unique_case_insensitive",
  ].includes(validator.validate)
    ? "column-validation"
    : "row-validation";

  return {
    message: validator.errorMessage || defaultMessage,
    level: validator.level || "error",
    type: validatorType,
  };
}
