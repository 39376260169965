import { batch } from "react-redux";
import {
  setCellTransformErrors,
  setTableMessages,
  clearAddRowQueue,
  clearRemoveRowQueue,
  updateSelectOptionOverrides,
  setSelectOptionOverrides,
  IRowToAddWithId,
} from "../store/reducers/coredata";
import { runValidators } from "../helpers/Validators";
import { isArray } from "lodash";
import i18next from "i18next";

import { AppThunk } from "../store/configureStore";
import {
  IColumnHookInput,
  IColumnHookOutput,
  IRowHookInput,
  IRowHookOutputInternal,
  IRowMeta,
  ITableMessage,
  ITableMessageInternal,
  INewTableMessages,
  IRowCellBasic,
} from "../interfaces";
import { IAbstractField } from "../fields";
import { transpose } from "../helpers/TableHelpers";
import { getDate } from "../helpers/date_magic";
import { SelectField } from "../fields/select";
import { DateField } from "../fields/datetime/date";
import { escapeRegExp } from "../util/regex";
import {
  selectMappedFieldInstances,
  selectMappedSpecs,
  selectKeyToIndexMap,
  ManyToOneIndexEntry,
  OneToOneIndexEntry,
  selectMappedSelectSpecs,
} from "../store/selectors";
import { TransformDataSuccess } from "./user_functions";
import { parseSelectedSheet, initializeCoredataData } from "./file_processing";
import {
  consoleErrorHandler,
  executeColumnHooks,
  executeRowDeleteHooks,
  executeRowHooks,
  HookErrorHandlerFn,
} from "../helpers/executeHooks";
import { addHookExceptionError } from "./hooks";
import { benchmarkStart, benchmarkEnd } from "../util/benchmark";
import {
  buildRowHookInputRows,
  CoreDataRowAction,
  filterTableMessagesForCell,
  FullDataWithMeta,
  HotChange,
  selectNewTableMessages,
  getRowMeta,
  selectNewTransformErrorCells,
  addTableMessage,
  getChangesAndMessagesFromHookOutput,
  filterTableMessages,
  addTableMessages,
  addOrRemoveRowsFromData,
  offsetTableMessagesAfterRowChange,
  offsetSetEntriesAfterRowChange,
  offsetSelectOptionOverrideMaps,
  SelectOptionOverride,
  buildRowIdToIndexMap,
  processFullDataChanges,
} from "../util/data_actions";

export type DataThunk = AppThunk<FullDataWithMeta>;
export type AsyncDataThunk = AppThunk<Promise<FullDataWithMeta>>;
export type RunColumnHooksFn = (
  fieldName: string,
  data: IColumnHookInput[]
) => Promise<IColumnHookOutput[]>;
export type RunRowHooksFn = (
  data: IRowHookInput[],
  mode: "init" | "update"
) => Promise<IRowHookOutputInternal[]>;
export type RunRowDeleteHooksFn = (data: IRowHookInput[]) => Promise<void>;

/**
 * Does all of the operations to prepare the state and data at the beginning
 * of the review step. Namely:
 * - Transform the select field values based on the mapping from the mapping step
 * - Runs all field transforms
 * - Runs column and row hooks
 * - Runs validations
 * Returns the full data ready for the table.
 */
export const initializeForReview = (
  fullData: FullDataWithMeta,
  runClientColumnHooks?: RunColumnHooksFn,
  runClientRowHooks?: RunRowHooksFn
): AsyncDataThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    let newFullData: FullDataWithMeta = [];

    newFullData = dispatch(stripHeaders(fullData));
    newFullData = dispatch(transformSelectFieldValues(newFullData));
    newFullData = dispatch(transformDateFixValues(newFullData));
    newFullData = dispatch(runFieldTransforms(newFullData));

    if (
      state.coredata.numRegisteredColHooks > 0 ||
      (state.settings.savedSchemaHooks.columnHooks &&
        state.settings.savedSchemaHooks.columnHooks.length > 0)
    ) {
      newFullData = await dispatch(
        runColumnHooks(newFullData, runClientColumnHooks)
      );
    }

    if (
      state.coredata.numRegisteredRowHooks > 0 ||
      (state.settings.savedSchemaHooks.rowHooks &&
        state.settings.savedSchemaHooks.rowHooks.length > 0) ||
      (state.settings.savedSchemaHooks.bulkRowHooks &&
        state.settings.savedSchemaHooks.bulkRowHooks.length > 0)
    ) {
      newFullData = await dispatch(
        runRowHooks(newFullData, runClientRowHooks, "init")
      );
    } else {
      // if we ran row hooks, that will run validations after.
      // otherwise we need to explicitly do it here.
      dispatch(runValidations(newFullData));
    }

    return newFullData;
  };
};

export const stripHeaders = (fullData: FullDataWithMeta): DataThunk => {
  return (_dispatch, getState) => {
    const { coredata, settings } = getState();
    const headerRowIndex =
      settings.headerRowOverride ?? coredata.rawDataHeaderRow;

    if (headerRowIndex === null) return fullData;

    return fullData.slice(headerRowIndex + 1);
  };
};

/**
 * Returns a fullData to its initial state.
 * Used when backtracking to the upload step
 */
export const clearFullData = (): AppThunk => {
  return (_dispatch, _getState, { setFullData }) => {
    setFullData([]);
  };
};

/**
 * Returns a fresh version of fullData. Used when going backwards from the
 * Data Review step
 */
export const resetFullData = (): AppThunk<Promise<void>> => {
  return async (dispatch, getState, { setFullDataAddMeta }) => {
    const { coredata } = getState();

    if (
      coredata.data.uploadType === "INITIAL_DATA_MAPPED" ||
      coredata.data.uploadType === "INITIAL_DATA_UNMAPPED"
    ) {
      setFullDataAddMeta(coredata.data.rawPreviewData);
    } else {
      const { success, data } = await dispatch(parseSelectedSheet());
      if (!success) {
        throw new Error("Could not re-parse file");
      }
      dispatch(initializeCoredataData(data));
      setFullDataAddMeta(data);
    }
  };
};

/**
 * Updates the data and state for user-initiated changes.
 * Accepts the full data, as well as an array of changes in the format
 * [rowIndex, colIndex, oldValue, newValue].
 * Note that this does *not* run row hooks.
 * Returns the new data with changes applied.
 */
export const changeCells = (
  fullData: FullDataWithMeta,
  changes: HotChange[]
): DataThunk => {
  return (dispatch) => {
    return dispatch(processChanges(fullData, changes));
  };
};

export const getErrorHandler = (): AppThunk<HookErrorHandlerFn> => {
  return (dispatch) =>
    process.env.JS_PLATFORM === "headless"
      ? (err: unknown, hookType: string) =>
          dispatch(addHookExceptionError(hookType, err))
      : consoleErrorHandler;
};
/**
 * Runs the row hooks over the given data with the given mode.
 * If changes is supplied, the row hooks will only be run on the rows
 * that had changes. Otherwise, they will run for every row.
 * Returns the new data with any changes from the hooks applied.
 */
export const runRowHooks = (
  fullData: FullDataWithMeta,
  runClientRowHooks: RunRowHooksFn | undefined,
  mode: "init" | "update",
  changes?: HotChange[]
): AsyncDataThunk => {
  return async (dispatch, getState) => {
    benchmarkStart("runRowHooks");
    const state = getState();
    const {
      settings: {
        savedSchemaHooks: { rowHooks, bulkRowHooks },
      },
    } = state;

    if (
      state.coredata.numRegisteredRowHooks === 0 &&
      (!rowHooks || rowHooks.length === 0) &&
      (!bulkRowHooks || bulkRowHooks.length === 0)
    )
      return fullData;

    if (state.settings.backendCapabilities.allow_hooks === false)
      return fullData;

    const changedRowIndexes = changes
      ? new Set(changes.map((c) => c[0]))
      : null;

    const rowHookInput = buildRowHookInputRows(
      state,
      fullData,
      changedRowIndexes
    );

    const rowOutputMap: Map<number, IRowHookOutputInternal> = new Map();

    if (rowHooks || bulkRowHooks) {
      (
        await executeRowHooks(
          rowHookInput,
          mode,
          rowHooks ?? [],
          bulkRowHooks ?? [],
          dispatch(getErrorHandler())
        )
      ).forEach((rowOutput) => rowOutputMap.set(rowOutput.index, rowOutput));
    }
    if (runClientRowHooks && state.coredata.numRegisteredRowHooks > 0) {
      (await runClientRowHooks(rowHookInput, mode)).forEach((rowOutput) =>
        rowOutputMap.set(rowOutput.index, rowOutput)
      );
    }

    const rowHookOutput: IRowHookOutputInternal[] = Array.from(
      rowOutputMap.values()
    );
    const [rowHookChanges, userMessages, selectOptionOverrides] =
      getChangesAndMessagesFromHookOutput(state, fullData, rowHookOutput);

    dispatch(updateSelectOptionOverrides(selectOptionOverrides));
    dispatch(updateUserMessages(userMessages));
    const newData = dispatch(processChanges(fullData, rowHookChanges));
    benchmarkEnd("runRowHooks");
    return newData;
  };
};

/**
 * Adds new empty rows at the specified row indexes.
 */
export const addEmptyRows = (
  fullData: FullDataWithMeta,
  addedRows: number[]
): DataThunk => {
  return addOrRemoveRows(fullData, addedRows, CoreDataRowAction.ADD);
};

/**
 * Removes the rows with the specified indexes, reruns validations,
 * and runs the row delete hooks.
 */
export const removeRows = (
  fullData: FullDataWithMeta,
  removedRows: number[],
  runDeleteHooks?: RunRowDeleteHooksFn
): DataThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const hookInput = buildRowHookInputRows(state, fullData, removedRows);
    if (state.settings.savedSchemaHooks.rowDeleteHooks) {
      executeRowDeleteHooks(
        hookInput,
        state.settings.savedSchemaHooks.rowDeleteHooks,
        dispatch(getErrorHandler())
      );
    }
    if (runDeleteHooks) {
      runDeleteHooks(hookInput);
    }
    const newFullData = dispatch(
      addOrRemoveRows(fullData, removedRows, CoreDataRowAction.REMOVE)
    );

    dispatch(runValidations(newFullData));

    return newFullData;
  };
};

/**
 * Runs all changes made by the transform data AI function
 */
export const runTransformDataChanges = (
  fullData: FullDataWithMeta,
  transformResult: TransformDataSuccess,
  runRowHooks: RunRowHooksFn,
  runRowDeleteHooks: RunRowDeleteHooksFn
): AppThunk<Promise<FullDataWithMeta>> => {
  return async (dispatch) => {
    let newFullData = await dispatch(
      processChangesAndRunRowHooks(
        fullData,
        transformResult.changes,
        runRowHooks
      )
    );

    newFullData = dispatch(
      removeRows(
        newFullData,
        [...transformResult.removedRowIndexes],
        runRowDeleteHooks
      )
    );

    return newFullData;
  };
};

/**
 * Updates the user messages in bulk.
 * Messages are supplied as a map where the keys are row indexes and
 * the values are records of field keys pointing to arrays of table
 * messages. For each cell given, the supplied messages will replace
 * any existing user-generated messages. Cells not specified will be
 * left unmodified.
 */
export const updateUserMessagesForCells = (
  newMessages: [rowIndex: number, colIndex: number, messages: ITableMessage[]][]
): AppThunk => {
  return (dispatch, getState) => {
    const columnMapping = getState().fields.columnMapping;
    const newUserMessages: INewTableMessages = [];

    newMessages.forEach(([rowIndex, colIndex, cellMessages]) => {
      if (columnMapping.has(colIndex) && isArray(cellMessages)) {
        const newMessages: ITableMessageInternal[] = cellMessages.map(
          (message) => {
            return {
              ...message,
              type: "user-generated",
            };
          }
        );
        newUserMessages.push([rowIndex, colIndex, newMessages]);
      }
    });

    dispatch(updateUserMessages(newUserMessages));
  };
};

/**
 * Does all necessary modifications to apply the given changes to the data.
 * Returns the new, modified data.
 */
export const processChanges = (
  fullData: FullDataWithMeta,
  changes: HotChange[]
): DataThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const tableMessages = selectNewTableMessages(state);
    const transformErrorCells = selectNewTransformErrorCells(state);
    const { highlightAutoFixes } = state.settings.reviewStep;

    const { newTransformErrorCells, newTableMessages, newFullData } =
      processFullDataChanges(
        fullData,
        changes,
        state.fields.fieldSpecs,
        state.fields.columnMapping,
        state.coredata.selectOptionOverrides,
        tableMessages,
        transformErrorCells,
        highlightAutoFixes
      );

    batch(() => {
      dispatch(setCellTransformErrors(newTransformErrorCells));
      dispatch(setTableMessages(newTableMessages));
    });

    return newFullData;
  };
};

/**
 * Uses the select field mapping from the column match step to transform
 * values in select fields to their canonical select option labels
 */
const transformSelectFieldValues = (fullData: FullDataWithMeta): DataThunk => {
  return (_dispatch, getState): FullDataWithMeta => {
    const transposedData = transpose(fullData);
    const state = getState();
    const { data } = state.coredata;
    const selectFields = selectMappedSelectSpecs(state);
    const { selectFieldMapping } = state.fields;

    for (const [colIndex, fieldSpec] of selectFields) {
      // If we don't have any data for this column, then just pass.
      // This happens if the user is doing manual entry.
      if (transposedData[colIndex] === undefined) continue;

      // If we have too many unique values, we don't allow custom mapping
      // In that case just make a good effort with labels/values/alt matches
      if (
        data.valCountsInColumn &&
        data.valCountsInColumn.has(colIndex) &&
        // We also skip the custom mapping if we have exact matches from labels/values/alt
        selectFieldMapping.has(colIndex)
      ) {
        transposedData[colIndex] = transposedData[colIndex].map((value) => {
          const selectMapping = selectFieldMapping.get(colIndex);
          // if we have a select mapping, check if the value is in the mapping
          const selectOption = selectMapping?.get(value as string);
          return selectOption?.label?.trim() ?? value;
        });
      } else {
        // a map of unique values to keys
        const selectOptions = fieldSpec.selectOptions.map(
          SelectField.normalizeOption
        );
        // a map of the field's option keys to option labels
        const optionKeyMap = new Map<string, string>();
        for (const { label, value, alternateMatches } of selectOptions) {
          [value, ...(alternateMatches ?? [])].forEach((match) =>
            optionKeyMap.set(match, label)
          );
        }

        transposedData[colIndex] = transposedData[colIndex].map((value) => {
          if (optionKeyMap.has(value as string)) {
            return optionKeyMap.get(value as string)!;
          }
          return value;
        });
      }
    }

    return transpose(transposedData) as FullDataWithMeta;
  };
};

/**
 * Transforms date field values to the display format.
 */
const transformDateFixValues = (fullData: FullDataWithMeta): DataThunk => {
  return (_dispatch, getState) => {
    const state = getState();

    if (state.fields.dateFixMapping.size === 0) return fullData;

    const mappedFieldInstances = selectMappedFieldInstances(state);
    const transposedData = transpose(fullData);

    for (const [colIndex, parseResult] of state.fields.dateFixMapping) {
      // If we don't have any data for this column, then just pass.
      // This happens if the user is doing manual entry.
      if (transposedData[colIndex] === undefined) continue;
      const fieldInstance = mappedFieldInstances.get(colIndex) as
        | DateField
        | undefined;
      if (!fieldInstance) continue;

      transposedData[colIndex] = transposedData[colIndex].map((value) => {
        if (typeof value !== "string" || value === "") return value;

        const dateTime = getDate(value, parseResult);
        if (!dateTime) return value;

        return fieldInstance.displayValueFromDateTime(dateTime);
      });
    }

    return transpose(transposedData) as FullDataWithMeta;
  };
};

/**
 * Runs field transforms on the complete dataset>
 * Updates table messages and transformErrorCells and returns
 * the transformed data.
 */
export const runFieldTransforms = (fullData: FullDataWithMeta): DataThunk => {
  return (dispatch, getState): FullDataWithMeta => {
    benchmarkStart("runFieldTransforms");
    const transposedData = transpose(fullData);
    const state = getState();
    const fieldInstances = selectMappedFieldInstances(state);
    const tableMessages = selectNewTableMessages(state);
    const transformErrorCells = selectNewTransformErrorCells(state);
    const { highlightAutoFixes } = state.settings.reviewStep;

    const newTransposedData = transposedData.map((colData, colIdx) => {
      const field = fieldInstances.get(colIdx);

      if (!field) return colData;

      return colData.map((rawValue, rowIdx) => {
        const transformResult = field.transformChecked(
          rawValue as string,
          rowIdx
        );
        const key = `${rowIdx},${colIdx}`;

        if (transformResult.empty) return "";

        if (transformResult.success) {
          const displayValue = (field as IAbstractField).getDisplayValue(
            transformResult.value,
            rowIdx
          );

          if (
            transformResult.highlight &&
            rawValue !== displayValue &&
            highlightAutoFixes
          ) {
            addTableMessage(tableMessages, rowIdx, colIdx, {
              type: "transform-highlight",
              level: "info",
              message: `Changed from "${rawValue}"`,
            });
          }

          return transformResult.value;
        } else {
          addTableMessage(
            tableMessages,
            rowIdx,
            colIdx,
            transformResult.message
          );
          transformErrorCells.add(key);
          return rawValue;
        }
      });
    });

    dispatch(setTableMessages(tableMessages));
    dispatch(setCellTransformErrors(transformErrorCells));

    benchmarkEnd("runFieldTransforms");
    return transpose(newTransposedData) as FullDataWithMeta;
  };
};

const runColumnHooks = (
  fullData: FullDataWithMeta,
  runClientColumnHooks?: RunColumnHooksFn
): AsyncDataThunk => {
  return async (dispatch, getState) => {
    benchmarkStart("runColumnHooks");
    const state = getState();
    if (state.settings.backendCapabilities.allow_hooks === false)
      return fullData;

    const transposedData = transpose(fullData);
    const tableMessages = selectNewTableMessages(state);
    const columnMapping = selectMappedSpecs(state);
    const changes: HotChange[] = [];

    for (const [colIndex, field] of columnMapping) {
      const colHookInputData: IColumnHookInput[] = (
        transposedData[colIndex] ?? []
      ).map((value, index) => ({
        value: value ?? "",
        index,
        rowId: (getRowMeta(fullData[index]) ?? {}).rowId,
      }));
      const combinedColHookOutput: IColumnHookOutput[] = colHookInputData.map(
        (_, index) => ({ index })
      );

      const savedColumnHooksForField = (
        state.settings.savedSchemaHooks.columnHooks ?? []
      ).filter((ch) => ch.fieldName === field.key);

      if (savedColumnHooksForField.length > 0) {
        const savedColHookOutput = await executeColumnHooks(
          savedColumnHooksForField,
          colHookInputData,
          dispatch(getErrorHandler())
        );
        savedColHookOutput.forEach(({ value, index, info }) => {
          if (value !== undefined) {
            combinedColHookOutput[index].value = value;
            colHookInputData[index].value = value;
          }
          if (info && info.length > 0) {
            combinedColHookOutput[index].info = info;
          }
        });
      }
      if (runClientColumnHooks) {
        const clientColHookOutput = await runClientColumnHooks(
          field.key,
          colHookInputData
        );
        clientColHookOutput.forEach(({ value, index, info }) => {
          if (value !== undefined) {
            combinedColHookOutput[index].value = value;
          }
          if (info && info.length > 0) {
            combinedColHookOutput[index].info = (
              combinedColHookOutput[index].info ?? []
            ).concat(info);
          }
        });
      }

      combinedColHookOutput.forEach((hookOutput) => {
        const rowIndex = hookOutput.index;

        if (hookOutput.info && hookOutput.info.length > 0) {
          const messages: ITableMessageInternal[] = hookOutput.info.map(
            (info) => ({ ...info, type: "user-generated" })
          );

          addTableMessages(tableMessages, rowIndex, colIndex, messages);
        }

        if (hookOutput.value !== undefined) {
          changes.push([
            rowIndex,
            colIndex,
            fullData[rowIndex][colIndex],
            hookOutput.value,
          ]);
        }
      });
    }

    dispatch(setTableMessages(tableMessages));
    const newData = dispatch(processChanges(fullData, changes));
    benchmarkEnd("runColumnHooks");
    return newData;
  };
};

export const runValidations = (fullData: FullDataWithMeta): AppThunk => {
  return (dispatch, getState) => {
    benchmarkStart("runValidations");
    const state = getState();

    const newMessages = filterTableMessages(
      state.coredata.tableMessages,
      (msg) => msg.type !== "column-validation" && msg.type !== "row-validation"
    );

    // run the validations, giving us all new validation messages
    // TODO: pass in the rows that have changed as a performance optimization
    const newValidatorTableMessages = runValidators(
      fullData,
      selectMappedSpecs(state),
      selectMappedFieldInstances(state),
      state.coredata.transformErrorCells,
      null
    );

    // add the validation messages to the user messages
    for (const [rowIndex, colIndex, messages] of newValidatorTableMessages) {
      addTableMessages(newMessages, rowIndex, colIndex, messages);
    }

    // add maxRecord warnings
    const maxRecords = state.settings.maxRecords;
    if (maxRecords !== null) {
      const numRows = fullData.length;
      const tableMessage: ITableMessageInternal = {
        type: "column-validation",
        level: "error",
        message: i18next.t("validations.maxRecordsExceeded", { maxRecords }),
      };

      for (const colIdx of state.fields.columnMapping.keys()) {
        for (let rowIdx = maxRecords; rowIdx < numRows; rowIdx++) {
          addTableMessage(newMessages, rowIdx, colIdx, tableMessage);
        }
      }
    }

    dispatch(setTableMessages(newMessages));
    benchmarkEnd("runValidations");
  };
};

const addOrRemoveRows = (
  fullData: FullDataWithMeta,
  affectedRowIndices: number[],
  mode: CoreDataRowAction
): DataThunk => {
  return (dispatch, getState) => {
    if (affectedRowIndices.length === 0) return fullData;
    const state = getState();
    const newFullData = addOrRemoveRowsFromData(
      state,
      fullData,
      affectedRowIndices,
      mode
    );

    const newTableMessages = offsetTableMessagesAfterRowChange(
      state.coredata.tableMessages,
      affectedRowIndices,
      mode
    );

    const newTransformErrorCells = offsetSetEntriesAfterRowChange(
      state.coredata.transformErrorCells,
      affectedRowIndices,
      mode
    );

    const newSelectOverrides = offsetSelectOptionOverrideMaps(
      state.coredata.selectOptionOverrides,
      affectedRowIndices,
      mode
    );

    batch(() => {
      dispatch(setCellTransformErrors(newTransformErrorCells));
      dispatch(setTableMessages(newTableMessages));
      dispatch(setSelectOptionOverrides(newSelectOverrides));
    });

    return newFullData;
  };
};

const updateUserMessages = (newUserMessages: INewTableMessages): AppThunk => {
  return (dispatch, getState) => {
    const newMessages = selectNewTableMessages(getState());

    newUserMessages.forEach(([rowIndex, colIndex, messages]) => {
      filterTableMessagesForCell(
        newMessages,
        rowIndex,
        colIndex,
        (msg) => msg.type !== "user-generated"
      );
      addTableMessages(newMessages, rowIndex, colIndex, messages);
    });

    dispatch(setTableMessages(newMessages));
  };
};

export const addRows = (
  newRows: IRowToAddWithId[],
  fullData: FullDataWithMeta
): DataThunk => {
  return (dispatch, getState) => {
    const rowsWithIndexes = newRows
      .filter((r) => r.index !== undefined)
      .sort((r1, r2) => r1.index! - r2.index!)
      .map((r, i) => ({ ...r, index: r.index! + i }));

    const rowsToAddToEnd = newRows.filter((r) => r.index === undefined);

    let newData: FullDataWithMeta = fullData;
    const overrides: SelectOptionOverride[] = [];
    const userMessages: INewTableMessages = [];
    const changes: HotChange[] = [];
    const keyToIndexMap = selectKeyToIndexMap(getState());

    const addRow = (newRow: IRowToAddWithId) => {
      let rowIndex = newRow.index ?? newData.length;
      if (rowIndex > newData.length) {
        rowIndex = newData.length;
      }

      newData = dispatch(addEmptyRows(newData, [rowIndex]));
      const metaColIndex = newData[rowIndex].length - 1;
      (newData[rowIndex][metaColIndex] as IRowMeta).rowId = newRow.rowId;

      for (const [key, cell] of Object.entries(newRow.row)) {
        const indexEntry = keyToIndexMap.get(key);
        if (!indexEntry) continue;

        let newCells: { cell: IRowCellBasic; colIndex: number }[];
        if (indexEntry.manyToOne) {
          newCells = (cell.manyToOne as IRowCellBasic[]).map((c, i) => ({
            cell: c,
            colIndex: indexEntry.indexes[i],
          }));
        } else {
          newCells = [{ cell, colIndex: indexEntry.index }];
        }

        for (const { cell, colIndex } of newCells) {
          if (cell.value !== undefined) {
            changes.push([rowIndex, colIndex, null, cell.value]);
          }
          if (cell.selectOptions) {
            overrides.push([rowIndex, colIndex, cell.selectOptions]);
          }
          if (cell.info) {
            userMessages.push([
              rowIndex,
              colIndex,
              cell.info.map((msg) => ({ ...msg, type: "user-generated" })),
            ]);
          }
        }
      }
    };

    rowsWithIndexes.forEach(addRow);
    rowsToAddToEnd.forEach(addRow);

    batch(() => {
      dispatch(updateSelectOptionOverrides(overrides));
      dispatch(updateUserMessages(userMessages));
      newData = dispatch(processChanges(newData, changes));
    });
    return newData;
  };
};

export const removeRowsById = (
  rowIds: string[],
  fullData: FullDataWithMeta
): DataThunk => {
  return (dispatch) => {
    let newFullData = fullData;
    const rowIdToIndexMap = buildRowIdToIndexMap(newFullData);
    // Sort them highest to lowest so we don't need to offset as we remove
    const sortedIndices = rowIds
      .map((rowId) => {
        const rowIndex = rowIdToIndexMap.get(rowId);
        if (rowIndex === undefined) {
          // eslint-disable-next-line no-console
          console.warn(`Remove Row: cannot find rowId: ${rowId}`);
        }
        return rowIndex;
      })
      .filter((idx) => idx !== undefined)
      .sort((idx1, idx2) => idx2 - idx1);
    sortedIndices.forEach((idx) => {
      newFullData = dispatch(removeRows(newFullData, [idx]));
    });
    return newFullData;
  };
};

export const addOrRemoveQueuedRows = (
  fullData: FullDataWithMeta
): DataThunk => {
  return (dispatch, getState) => {
    let newData = [...fullData];
    const { rowsToAdd, rowsToDelete } = getState().coredata;
    if (rowsToAdd.length > 0) {
      newData = dispatch(addRows(rowsToAdd, newData));
      dispatch(clearAddRowQueue());
    }

    if (rowsToDelete.length > 0) {
      newData = dispatch(removeRowsById(rowsToDelete, newData));
      dispatch(clearRemoveRowQueue());
    }
    return newData;
  };
};

export interface SearchOpts {
  fullCell: boolean;
  caseSensitive: boolean;
  field: string | null;
  manyToOneIndex: number | null;
  // onlyCellsWithErrors: boolean;
}

export interface SearchMatch {
  rowIndex: number;
  colIndex: number;
  cellValue: string;
}

// field types where the display values differ from stored values
const typesToGetDisplayValue = ["date", "time", "datetime", "number"];

export const search = (
  fullData: FullDataWithMeta,
  query: string,
  opts?: SearchOpts
): AppThunk<{
  matches: Map<string, string>;
  regex: {
    regexStr: string;
    flags: string;
  };
  unmatchedRows: Set<number>;
}> => {
  return (_dispatch, getState) => {
    const matches: Map<string, string> = new Map();
    const unmatchedRows: Set<number> = new Set();
    const state = getState();
    const { transformErrorCells } = state.coredata;
    const keyToIndexMap = selectKeyToIndexMap(state);
    const fieldInstances = selectMappedFieldInstances(state);

    let replaceColIndex: number | null = null;

    if (opts?.field) {
      const indexEntry = keyToIndexMap.get(opts.field);
      if (opts.manyToOneIndex !== null) {
        replaceColIndex = (indexEntry as ManyToOneIndexEntry).indexes[
          opts.manyToOneIndex
        ];
      } else {
        replaceColIndex = (indexEntry as OneToOneIndexEntry).index;
      }
    }

    const regexStr =
      opts?.fullCell || query.length === 0
        ? `^${escapeRegExp(query)}$`
        : escapeRegExp(query);

    const flags = opts?.caseSensitive ? "g" : "gi";
    let regex: RegExp;

    fullData.forEach((row, rowIndex) => {
      let isRowMatched = false;
      row.forEach((cell, colIndex) => {
        regex = new RegExp(regexStr, flags);
        if (replaceColIndex !== null && replaceColIndex !== colIndex) return;
        const field = fieldInstances.get(colIndex);
        if (!field) return;
        if (field.type === "checkbox") return;

        if (typesToGetDisplayValue.includes(field.type)) {
          const cellKey = `${rowIndex},${colIndex}`;
          if (!transformErrorCells.has(cellKey)) {
            // @ts-expect-error Can't convince TS that cell matches the right type here
            cell = field.getDisplayValueChecked(cell);
          }
        }

        if (typeof cell !== "string") return;

        if (regex.test(cell)) {
          matches.set(`${rowIndex},${colIndex}`, cell);
          isRowMatched = true;
        }
      });

      if (!isRowMatched) {
        unmatchedRows.add(rowIndex);
      }
    });

    return {
      matches,
      regex: {
        regexStr,
        flags,
      },
      unmatchedRows,
    };
  };
};

/*
 * Runs the given find-and-replace operation as requested by the user.
 */
export const findAndReplace = (
  fullData: FullDataWithMeta,
  runClientRowHooks: RunRowHooksFn,
  query: string,
  replaceVal: string,
  opts: SearchOpts
): AsyncDataThunk => {
  return async (dispatch) => {
    const changes: HotChange[] = [];
    const { matches, regex } = dispatch(search(fullData, query, opts));

    // escape special replacement patterns with $ literals
    const replaceStr = replaceVal.replace("$", "$$");

    for (const [indexes, previousValue] of matches) {
      const [rowIndex, colIndex]: number[] = indexes
        .split(",")
        .map((val) => parseInt(val, 10));
      const regexp = new RegExp(regex.regexStr, regex.flags);
      const newVal = previousValue.replace(regexp, replaceStr);
      if (newVal !== previousValue) {
        changes.push([rowIndex, colIndex, previousValue, newVal]);
      }
    }

    if (changes.length === 0) return fullData;

    return await dispatch(
      processChangesAndRunRowHooks(fullData, changes, runClientRowHooks)
    );
  };
};

export const processChangesAndRunRowHooks = (
  fullData: FullDataWithMeta,
  changes: HotChange[],
  runClientRowHooks: RunRowHooksFn
): AsyncDataThunk => {
  return async (dispatch) => {
    const updateData = dispatch(processChanges(fullData, changes));
    return await dispatch(
      runRowHooks(updateData, runClientRowHooks, "update", changes)
    );
  };
};
